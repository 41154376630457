// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lokalizacja-js": () => import("./../../src/pages/lokalizacja.js" /* webpackChunkName: "component---src-pages-lokalizacja-js" */),
  "component---src-pages-o-inwestycji-js": () => import("./../../src/pages/o-inwestycji.js" /* webpackChunkName: "component---src-pages-o-inwestycji-js" */),
  "component---src-pages-park-ochrony-bieszczadzkiej-fauny-js": () => import("./../../src/pages/park-ochrony-bieszczadzkiej-fauny.js" /* webpackChunkName: "component---src-pages-park-ochrony-bieszczadzkiej-fauny-js" */),
  "component---src-pages-villa-collis-js": () => import("./../../src/pages/villa-collis.js" /* webpackChunkName: "component---src-pages-villa-collis-js" */)
}

